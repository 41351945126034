import { Popover as BootstrapPopover } from "bootstrap";
import { convertToBase64 } from "./storage_utils";
export let popoverInstance = null;

export const handleEventMouseEnter = (info) => {
  if (popoverInstance) {
    popoverInstance.dispose();
    popoverInstance = null;
  }

  if (info.event._def.extendedProps.description) {
    const file = info.event._def.extendedProps.file;
    // TO DO dedupe from event_utils
    const fileHtml =
      file && file.str && file.extension !== "pdf"
        ? `<img
                src=${convertToBase64(file)}
                style="width:auto;height:40px"
              />`
        : "<span/>";
    popoverInstance = new BootstrapPopover(info.el, {
      title: info.event._def.title,
      content: `
              <div>
              ${fileHtml}
              <p/>
              <p>${info.event._def.extendedProps.description}</p>
              <div>`,
      html: true,
      placement: "top",
      trigger: "hover",
      container: "body",
      template:
        '<div class="popover custom-bs-popover" role="tooltip"><div class="popover-arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
    });

    popoverInstance.show();
  }
};

export const handleEventMouseLeave = () => {
  if (popoverInstance) {
    popoverInstance.dispose();
    popoverInstance = null;
  }
};
