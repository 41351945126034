import {
  Flex,
  Grid,
  Image,
  Link,
  Text,
  useTheme as useAmplifyTheme,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import styled from "styled-components";
import { useMemo } from "react";
import logoSignIn from "../assets/images/logo_signin.png";
import { setLocalStorageItem } from "../utils/user_utils";
import { v4 as uuid4 } from "uuid";
import { logPageView } from "../utils/view_utils";

export const components = {
  Header() {
    const { tokens } = useAmplifyTheme();
    return (
      <Grid gap={tokens.space.small} padding={tokens.space.large}>
        <View>
          <Image
            alt="Trippy logo "
            src={logoSignIn}
            style={{ width: "100%", height: "auto" }}
          />
        </View>
        <Flex
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          wrap="nowrap"
        >
          <View textAlign="center">
            <Text color={tokens.colors.neutral[80]}>
              A collaborative travel itinerary builder &#8482;
            </Text>
          </View>
        </Flex>
      </Grid>
    );
  },
  Footer() {
    const { tokens } = useAmplifyTheme();
    return (
      <Flex
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
        gap="1rem"
      >
        <View textAlign="center" padding={tokens.space.medium}>
          <Link
            color={tokens.colors.neutral[80]}
            href={`${window.location}termsofuse`}
          >
            Terms
          </Link>
        </View>
        <View textAlign="center" padding={tokens.space.medium}>
          <Link
            color={tokens.colors.neutral[80]}
            href={`${window.location}privacypolicy`}
          >
            Privacy Policy
          </Link>
        </View>
        <View textAlign="center" padding={tokens.space.medium}>
          <Link
            color={tokens.colors.neutral[80]}
            href={`${window.location}copyrightpolicy`}
          >
            Copyright&copy;
          </Link>
        </View>
      </Flex>
    );
  },
};

export const signUpAttributes = ["email", "given_name", "family_name"];
export const formFields = {
  signUp: {
    email: {
      order: 1,
    },
    given_name: {
      order: 2,
      placeholder: "Enter your Frst Name",
      label: "First name",
    },
    family_name: {
      order: 3,
      placeholder: "Enter your Last Name",
      label: "Last name",
    },
    password: {
      order: 4,
    },
    confirm_password: {
      order: 5,
    },
  },
};

// https://ui.docs.amplify.aws/react/guides/css-in-js
export const withStyledAuthenticator = (Component) => {
  if (!localStorage.getItem("anonymous_visitor_token"))
    setLocalStorageItem({ key: "anonymous_visitor_token", value: uuid4() });

  logPageView({
    userId: localStorage.getItem("user_id") || null,
    sessionId: localStorage.getItem("session_id") || null,
    url: window.location.href,
    referrerUrl:
      window.location.href === localStorage.getItem("referrer_url")
        ? null
        : localStorage.getItem("referrer_url"),
    anonymousVisitorToken: localStorage.getItem("anonymous_visitor_token"),
  });

  const StylableAuthenticator = ({ className, ...props }) => {
    const Authenticator = useMemo(() => {
      return withAuthenticator(Component, {
        signUpAttributes,
        formFields,
        components,
        variation: "default",
        className,
      });
    }, [className]);
    return <Authenticator {...props} />;
  };
  return styled(StylableAuthenticator)`
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    min-height: 100vh;
    background-image: linear-gradient(
      to left top,
      #73a2ce,
      #7ca8d2,
      #85aed6,
      #8db5db,
      #96bbdf,
      #a4c2e4,
      #b2c9e8,
      #bfd0ec,
      #d2dbf1,
      #e3e6f5,
      #f2f2fa,
      #ffffff
    );
  `;
};
