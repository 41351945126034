import { Button, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  fetchTripsForUser,
  saveTrip,
  toggleTripPublic,
} from "../../utils/trip_utils";
import {
  adaptAiResponseToEvent,
  getPhotoMetaData,
  saveEvent,
} from "../../utils/event_utils";
import { uploadFile } from "../../utils/storage_utils";
import { RequiredFieldsTooltip } from "../../constants";
import imageCompression from "browser-image-compression";

export const SaveTripButton = ({
  title,
  startDate,
  endDate,
  userId,
  tripId,
  location,
  lat,
  lng,
  description,
  setTrips,
  setTripAlerts,
  fullWidth,
  loading,
  setLoading,
  uploadedFiles,
  soloTraveler,
  variant = "contained",
  callee,
  setProgress,
  setActiveStep = undefined,
  isPublic = false,
}) => {
  const navigate = useNavigate();

  const geotaggingEnabled =
    localStorage.getItem("enable_geotagging") === "true";

  const handleOnClick = async () => {
    setProgress && setProgress({ value: 0, label: "Creating trip" });
    setLoading && setLoading(true);
    setTripAlerts && setTripAlerts(null);
    if (callee === "newtripdialog" && setActiveStep) setActiveStep(undefined);

    let savedTrip = undefined;

    if (geotaggingEnabled && uploadedFiles?.length) {
      setProgress({ value: 0, label: "Reading photos (1/2)" });
      const generateMetadataTimeout = setTimeout(() => {
        setTripAlerts([
          {
            severity: "error",
            message: "Something went wrong. Try again.",
          },
        ]);
        if (callee === "newtripdialog" && setActiveStep) setActiveStep(2);
        setLoading(false);
        return;
      }, 75000);

      const resizedFiles = await Promise.all(
        uploadedFiles.map(async (file) => {
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            preserveExif: true,
          };
          try {
            const compressedFile = await imageCompression(file, options);
            return compressedFile;
          } catch (error) {
            console.log(error);
          }
        })
      );

      const { status, aiResults, message } = await getPhotoMetaData({
        files: resizedFiles,
        soloTraveler,
      });

      if (status === 200 && aiResults) {
        setProgress({
          value: 0,
          label: "Creating trip and events (2/2)",
        });

        savedTrip = await saveTrip({
          title,
          startDate,
          endDate,
          userId,
          tripId,
          location,
          lat,
          lng,
          description,
        });

        const aiEvents = adaptAiResponseToEvent({
          aiResults,
          userId,
          tripId: savedTrip.id,
        });

        let savedAiEvents = await Promise.all(
          aiEvents?.map(async (aiEvent, index) => {
            if (aiEvent) {
              const { file, ...rest } = aiEvent;
              const savedAiEvent = await saveEvent({ ...rest });
              if (savedAiEvent) {
                await uploadFile({
                  id: savedAiEvent.id,
                  file: uploadedFiles[index],
                  bucket: "events",
                });
              }
              return { ...savedAiEvent, file };
            }
          })
        );

        clearTimeout(generateMetadataTimeout);
      } else if (status === 500) {
        setTripAlerts([{ severity: "error", message }]);
        if (callee === "newtripdialog" && setActiveStep) setActiveStep(2);
        setLoading(false);
      }
    } else {
      savedTrip = await saveTrip({
        title,
        startDate,
        endDate,
        userId,
        tripId,
        location,
        lat,
        lng,
        description,
      });
    }

    if (isPublic && savedTrip.is_public === false) {
      await toggleTripPublic({
        id: savedTrip.id,
        isPublic,
      });
    }

    if (setTripAlerts && callee === "tripdetail") {
      setTripAlerts([
        {
          severity: "success",
          message: "Trip updated",
        },
      ]);
      setTimeout(() => {
        setTripAlerts(null);
      }, [5000]);
    }

    fetchTripsForUser({ userId }).then((trips) => {
      if (trips) setTrips(trips);
      if (savedTrip && tripId !== savedTrip.id) {
        navigate(`/trip/${savedTrip.id}`);
      }
    });
  };

  return (
    <Tooltip
      title={
        title && location && startDate && endDate ? "" : RequiredFieldsTooltip
      }
    >
      <span>
        <Button
          variant={variant}
          onClick={handleOnClick}
          disabled={
            title &&
            location &&
            startDate &&
            endDate &&
            endDate > startDate &&
            !loading
              ? false
              : true
          }
          fullWidth={fullWidth}
        >
          Save
        </Button>
      </span>
    </Tooltip>
  );
};
