import { AppContext } from "../contexts";
import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  EditTripCalendar,
  Loader,
  MapView,
  TripShareDetail,
  TripDetail,
  ViewBar,
} from "./";
import {
  EditCalendarOptions,
  TripExpandSidebarTooltip,
  TripCollapseSidebarTooltip,
} from "../constants";
import { fetchEventsForTrip, filterEventsByDate } from "../utils/event_utils";
import { validatePlaces } from "../utils/maps_utils";
import { convertToBase64 } from "../utils/storage_utils";

export const Trip = () => {
  const {
    trips,
    events,
    startDate,
    endDate,
    setEventFileStr,
    setEventFileExtension,
  } = useContext(AppContext);
  const { trip_id } = useParams();

  const theme = useTheme();
  const screenIsMediumOrUp = useMediaQuery(theme.breakpoints.up("md"));
  const smallScreenSize = theme.breakpoints.values.sm;
  const screenIsSmall = useMediaQuery(`(max-width:${smallScreenSize}px)`);
  const [expanded, setExpanded] = useState(
    screenIsMediumOrUp ? "overview" : ""
  );
  const [tripView, setTripView] = useState(
    localStorage.getItem(`edit_${trip_id}_view`) || "calendar"
  );
  const [places, setPlaces] = useState(null);
  const [validPlaces, setValidPlaces] = useState(null);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [calendarApi, setCalendarApi] = useState(null);
  const [currentDay, setCurrentDay] = useState(0);
  const [calendarTitle, setCalendarTitle] = useState(undefined);
  const [isPublic, setIsPublic] = useState(false);
  const savedCalendarView =
    localStorage.getItem(trip_id) && JSON.parse(localStorage.getItem(trip_id))
      ? JSON.parse(localStorage.getItem(trip_id)).calendarView
      : null;
  const [calendarView, setCalendarView] = useState(savedCalendarView);
  const [eventClicked, setEventClicked] = useState(undefined);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [filteredStartDate, setFilteredStartDate] = useState(undefined);
  const [filteredEndDate, setFilteredEndDate] = useState(undefined);

  const collapsedWidth = "45px";
  const expandedWidth = "33%";

  // on resize ensure expanded on desktop, collapsed on mobile
  useEffect(() => {
    if (screenIsMediumOrUp) {
      setSidebarCollapsed(false);
    } else {
      setExpanded(true);
      setSidebarCollapsed(false);
    }
  }, [screenIsMediumOrUp]);

  useEffect(() => {
    if (trip_id) {
      fetchEventsForTrip({
        tripId: trip_id,
        callee: "edit",
      }).then((events) => {
        setPlaces(events);
        setValidPlaces(validatePlaces(events.all));
      });
    }
  }, [trip_id, setPlaces, events]);

  useEffect(() => {
    if (validPlaces?.length === 0) {
      localStorage.setItem(`edit_${trip_id}_view`, "calendar");
      setTripView("calendar");
    }
  }, [validPlaces]);

  useEffect(() => {
    filterEventsByDate({
      startDate: filteredStartDate,
      endDate: filteredEndDate,
      events: places,
      callback: setPlaces,
    });
  }, [filteredEndDate, filteredStartDate, tripView]);

  const handleDrawer = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  return trips && events ? (
    <Grid container spacing={2}>
      <Box
        sx={{
          textAlign: "left",
          transition: theme.transitions.create("all", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: {
            xs: "100%",
            md: sidebarCollapsed ? collapsedWidth : `calc(${expandedWidth})`,
          },
        }}
        pt={2}
        pl={2}
      >
        {screenIsMediumOrUp ? (
          <Stack direction="row" spacing={1} justifyContent="flex-start" mb={1}>
            <Tooltip
              title={
                sidebarCollapsed
                  ? TripExpandSidebarTooltip
                  : TripCollapseSidebarTooltip
              }
            >
              <IconButton onClick={handleDrawer}>
                {sidebarCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </Tooltip>
          </Stack>
        ) : (
          ""
        )}
        <TripDetail
          expanded={expanded}
          setExpanded={setExpanded}
          sidebarCollapsed={sidebarCollapsed}
        />
        <TripShareDetail
          expanded={expanded}
          setExpanded={setExpanded}
          sidebarCollapsed={sidebarCollapsed}
        />
      </Box>
      <Box
        sx={{
          transition: theme.transitions.create("all", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: {
            xs: "100%",
            md: sidebarCollapsed
              ? `calc((100% - ${collapsedWidth}))`
              : `calc((100% - ${expandedWidth}))`,
          },
        }}
        pt={1}
        pl={2}
      >
        <ViewBar
          calendarTitle={calendarTitle}
          setCalendarTitle={setCalendarTitle}
          calendarView={calendarView}
          setCalendarView={setCalendarView}
          setEventClicked={setEventClicked}
          setDialogOpen={setDialogOpen}
          currentDay={currentDay}
          setCurrentDay={setCurrentDay}
          startDate={startDate}
          endDate={endDate}
          calendarApi={calendarApi}
          showAddEventButton={true}
          showFavorites={null}
          events={events}
          calendarViewOptions={EditCalendarOptions}
          showShareButton={isPublic}
          validPlaces={validPlaces}
          tripView={tripView}
          setTripView={setTripView}
          callee="edit"
          filteredStartDate={filteredStartDate}
          setFilteredStartDate={setFilteredStartDate}
          filteredEndDate={filteredEndDate}
          setFilteredEndDate={setFilteredEndDate}
        />

        {/* 
        always render trip calendar so calendar api is defined
        and only render when tripView is map for styling purposes
        ShareTrip behaves the same way 
        */}

        <EditTripCalendar
          sidebarCollapsed={sidebarCollapsed}
          calendarApi={calendarApi}
          setCalendarApi={setCalendarApi}
          setIsPublic={setIsPublic}
          calendarView={calendarView}
          eventClicked={eventClicked}
          setEventClicked={setEventClicked}
          setCalendarView={setCalendarView}
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          events={events.all} // only send all events to EditTripCalendar component
          styleOverrides={{
            visibility: tripView === "calendar" ? "visible" : "hidden",
            height: tripView === "calendar" ? "auto" : 0,
            position: "relative",
          }}
        />

        {tripView === "map" ? (
          <Grid
            container
            sx={{
              minHeight: screenIsSmall ? "400px" : "600px",
            }}
          >
            <MapView
              places={places}
              callee="trip"
              styleOverrides={{
                height: screenIsSmall ? "400px" : "600px",
              }}
              // TO DO combine with handleEventClick??
              onClickOverride={(id) => {
                if (events?.all.length) {
                  const matchingEvent = events.all.find((item) => {
                    return item.id === id;
                  });
                  setEventClicked(matchingEvent);
                  if (matchingEvent.file.message === "File not found") {
                    setEventFileStr(matchingEvent.file.message);
                  } else if (!matchingEvent.file.error) {
                    setEventFileStr(convertToBase64(matchingEvent.file));
                    setEventFileExtension(matchingEvent.file.extension);
                  }
                  setDialogOpen(true);
                }
              }}
            />
          </Grid>
        ) : (
          ""
        )}
      </Box>
    </Grid>
  ) : (
    <Loader />
  );
};
