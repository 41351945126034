import { generateHTML } from "@tiptap/core";
import { isJsonString } from "../utils/text_utils";
import { extensions } from "../utils/tiptap_utils";
import { convertToBase64 } from "./storage_utils";

export const getLatLng = async ({ placedId }) => {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placedId}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
    );
    const json = await response.json();
    return json;
  } catch (e) {
    console.log({ e });
  }
};

// https://developers.google.com/maps/documentation/geocoding/requests-reverse-geocoding
export const reverseGeocode = async ({ latLng, type = "locality" }) => {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${encodeURIComponent(
        latLng
      )}&result_type=${type}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
    );
    const json = await response.json();
    return json;
  } catch (e) {
    console.log({ e });
  }
};

export const validatePlaces = (arr) => {
  if (arr) {
    const validPlaces = [];
    arr.forEach((place) => {
      const { id, lat, lng, ...rest } = place;
      if (id && parseFloat(lat) && parseFloat(lng)) {
        validPlaces.push({
          lat: parseFloat(lat),
          lng: parseFloat(lng),
          id: id,
          ...rest,
        });
      }
    });
    return validPlaces;
  }
  return [];
};

export const infoWindowHtmlHelper = ({ callee, place }) => {
  let href = "";
  if (callee === "profile" || callee === "explore") {
    href = `/trip/share/${place.id}`;
  } else if ((callee === "trip" || callee === "sharetrip") && place.location) {
    href = `http://www.google.com/search?q=${place.location}`;
  }
  // html for infoWindow should be same for trip and sharetrip
  // and profile and explore
  const infoWindowHeader =
    callee === "trip" || callee === "sharetrip" ? (
      <h6
        style={{
          textAlign: "left",
        }}
      >
        {place.title}
        {place.favorite ? (
          <i className="bi bi-heart-fill" style={{ marginLeft: "10px" }}></i>
        ) : (
          ""
        )}
      </h6>
    ) : (
      <a
        href={href}
        className="inline-link"
        style={{
          textAlign: "left",
        }}
      >
        <h6>
          {place.title}
          <i
            className="bi bi-box-arrow-up-right"
            style={{ marginLeft: "10px" }}
          ></i>
        </h6>
      </a>
    );

  let infoWindowImage = "";
  if (
    place.file &&
    place.file.str &&
    (place.file.extension === "jpeg" || place.file.extension === "png")
  ) {
    infoWindowImage =
      callee === "trip" || callee === "sharetrip" ? (
        <>
          <img
            src={convertToBase64(place.file)}
            style={{ maxWidth: "200px", height: "auto" }}
            alt="event-image"
          />
          <p />
        </>
      ) : (
        ""
      );
  }

  let infoWindowLocation = "";
  if (place.location) {
    infoWindowLocation =
      callee === "trip" || callee === "sharetrip" ? (
        <>
          <a
            target="_blank"
            rel="noreferrer"
            href={href}
            style={{ textDecoration: "underline" }}
          >
            <p>
              {place.location}
              <i
                className="bi bi-box-arrow-up-right"
                style={{ marginLeft: "10px" }}
              ></i>
            </p>
          </a>
          <p />
        </>
      ) : (
        <>
          <p>{place.location}</p>
          <p />
        </>
      );
  }

  let infoWindowDescription = (
    <div>{place.description ? <p>{place.description}</p> : ""}</div>
  );
  const isJson = isJsonString(place.description);
  if (isJson) {
    const parsedDescription = JSON.parse(place.description);
    infoWindowDescription = parsedDescription.content && (
      <div
        dangerouslySetInnerHTML={{
          __html: generateHTML(parsedDescription, extensions),
        }}
      />
    );
  }

  return {
    infoWindowHeader,
    infoWindowLocation,
    infoWindowDescription,
    infoWindowImage,
  };
};

export const glyphHtmlHelper = ({ callee, place, theme, index }) => {
  const glyphBackground =
    callee === "trip" || callee === "sharetrip"
      ? theme.palette.events[place.type || undefined]
      : theme.palette.primary.main;
  const glyphColor = theme.palette.common.white;
  const glyphBorderColor = theme.palette.common.white;
  let glyphText =
    callee === "trip" || callee === "sharetrip" ? (index + 1).toString() : null;

  // TO DO incorporate event type as icon
  const icon = document.createElement("div");
  icon.innerHTML = '<i class="bi bi-heart-fill"/>';
  if (place.favorite) {
    glyphText = icon;
  }
  return { glyphBackground, glyphColor, glyphBorderColor, glyphText };
};
